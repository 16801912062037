// 清空缓存策略
import {
  BALANCE_LIST_NAME,
  BALANCE_DETAIL_NAME
} from '~/const/component-name';
import { BALANCE_LIST_STORE } from '~/stores/pages/balance';
import { BALANCE_DETAIL_STORE } from '~/stores/pages/balance/detail';
import { TagListItemType } from './model';

type CacheClearStrategyType = Record<string, (tag: TagListItemType) => void>;

export const CACHE_CLEAR_STRATEGY: CacheClearStrategyType = {
  // 结算单列表
  [BALANCE_LIST_NAME]: () => BALANCE_LIST_STORE().clear(),
  // 结算单详情
  [BALANCE_DETAIL_NAME]: tag => BALANCE_DETAIL_STORE().clearDetailByTag(tag)
};