import { defineStore } from 'pinia';
import { ref } from 'vue';
import { BalanceFormType, BalanceListType } from './model';
import API_BALANCE from '~/api/balance';
import { BalanceListItemType } from '~/models/balance';
import { BALANCE_DETAIL_STORE } from './detail';

export const BALANCE_LIST_STORE = defineStore('BALANCE_LIST_STORE', () => {
  const loading = ref(false);

  const year = `${new Date().getFullYear()}`;
  const form = ref<BalanceFormType>({
    pageNum: 1,
    pageSize: 10,
    billType: 'all',
    date: ['', ''],
    status: 'all',
    invoiceStatus: 'all',
    year,
    quarter: 'Q1'
  });

  const balanceList = ref<BalanceListType>({
    data: [],
    total: 0
  });

  const getBillCycleParams = () => {
    const { year, quarter, billType } = form.value;
    if (billType === '1' && year && quarter) {
      return `${year}-${quarter}`;
    }
    return undefined;
  };
  
  // 获取结算单参数
  const getBalanceListParams = () => {
    const { pageNum, pageSize, billType, date, status, invoiceStatus } = form.value;
    const [startTime, endTime] = date || [];
    const billCycle = getBillCycleParams();
    return {
      pageNum,
      pageSize,
      billType: billType !== 'all' ? billType : undefined,
      status: status !== 'all' ? status : undefined,
      startTime: startTime || undefined,
      endTime: endTime || undefined,
      invoiceStatus: invoiceStatus !== 'all' ? invoiceStatus : undefined,
      billCycle
    };
  };

  // 遍历结算单列表 修改详情状态
  const updateBalanceDetailStatus = (balanceList: BalanceListItemType[]) => {
    const store = BALANCE_DETAIL_STORE();
    for (let i = 0; i < balanceList.length; i += 1) {
      const { id, status } = balanceList[i];
      store.updateBalanceDetailStatusById(`${id}`, status);
    }
  };
  
  // 获取结算单列表
  const getBalanceList = async () => {
    loading.value = true;
    const params = getBalanceListParams();
    const res = await API_BALANCE.getBalanceList(params);
    balanceList.value.data = res.rows;
    balanceList.value.total = res.total;
    updateBalanceDetailStatus(balanceList.value.data);
    loading.value = false;
  };

  // 根据id刷新结算单列表
  const refreshBalanceListByBalanceId = (balanceId: string) => {
    // 若结算单列表有数据 则说明页面有缓存
    if (balanceList.value.data.length) {
      // 查找传入的balanceId是否存在 若存在则刷新缓存数据
      const index = balanceList.value.data.findIndex(item => `${item.id}` === balanceId);
      if (index >= 0) {
        getBalanceList();
      }
    }
  };

  // 重置查询参数
  const resetSearchParams = () => {
    form.value.billType = 'all';
    form.value.date = ['', ''];
    form.value.status = 'all';
    form.value.invoiceStatus = 'all';
    form.value.year = year;
    form.value.quarter = 'Q1';
  };

  // 清空缓存
  const clear = () => {
    loading.value = false;
    form.value.pageNum = 1;
    form.value.pageSize = 10;
    resetSearchParams();
    balanceList.value.data = [];
    balanceList.value.total = 0;
  };

  return {
    loading,
    form,
    balanceList,
    getBalanceList,
    resetSearchParams,
    refreshBalanceListByBalanceId,
    clear
  };
});