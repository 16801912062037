import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { BalanceDetailType, PolicListItemType } from './model';
import { BalanceListItemType } from '~/models/balance';
import API_BALANCE from '~/api/balance';
import { TagListItemType } from '~/stores/tags/model';
import {
  STATUS_DIC,
  V1_POLICY_TITLE_LIST,
  V1_STATUS_AMOUNT_KEYS,
  V2_POLICY_TITLE_LIST,
  V2_STATUS_AMOUNT_KEYS
} from './const';

export const BALANCE_DETAIL_STORE = defineStore('BALANCE_DETAIL_STORE', () => {

  // 结算单id
  const balanceId = ref('');

  // 结算单详情map
  const balanceDetailMap = ref<Map<string, BalanceDetailType>>(new Map());

  // 加载状态
  const loading = computed(() => {
    const data = balanceDetailMap.value.get(balanceId.value);
    if (data) {
      return data.loading;
    }
    return false;
  });

  // 结算单数据
  const balanceData = computed(() => {
    const detail = balanceDetailMap.value.get(balanceId.value);
    if (detail && Object.keys(detail.data).length) {
      return detail.data;
    }
    return undefined;
  });

  // 结算单标题
  const title = computed(() => {
    if (balanceData.value) {
      const { billCycle } = balanceData.value;
      const dateList = billCycle.split('-');
      const [year] = dateList;
      const fiscal = `${+year + 1}`;
      return `${dateList.join('')}FY${fiscal.slice(2)}`;
    }
    return '--';
  });

  // 业绩
  const amount = computed(() => {
    if (balanceData.value) {
      const { version, actualPaymentAmount, ratingAmount } = balanceData.value;
      return version === 'v1' ? actualPaymentAmount || 0 : ratingAmount || 0;
    }
    return '--';
  });

  // 结算单状态
  const balanceStatus = computed(() => {
    if (balanceData.value) {
      return STATUS_DIC[balanceData.value.status];
    }
    return '--';
  });

  // 获取政策列表数据
  const getPolicyListData = (data: BalanceListItemType) => {
    const { version, id, calculateId, billType, billCycle } = data;
    const keys = version === 'v1' ? V1_STATUS_AMOUNT_KEYS : V2_STATUS_AMOUNT_KEYS;
    const titles = version === 'v1' ? V1_POLICY_TITLE_LIST : V2_POLICY_TITLE_LIST;
    const type = billType === 0 ? '月度' : '季度';
    const list: PolicListItemType[] = [];
    for (let i = 0; i < keys.length; i += 1) {
      const { status: statusKey, amount: amountKey } = keys[i];
      const title = titles[i];
      const name = title.replace(/\${type}/, type);
      const status = data[statusKey];
      const amount = data[amountKey] || 0;
      list.push({
        id,
        calculateId,
        name,
        status,
        amount,
        key: statusKey,
        billType,
        billCycle,
        version
      });
    }
    return list.filter(item => typeof item.status === 'number' && item.status !== 9);
  };

  // 政策列表
  const policyList = computed(() => {
    const detail = balanceDetailMap.value.get(balanceId.value);
    if (detail && Object.keys(detail.data)) {
      return getPolicyListData(detail.data);
    }
    return [];
  });

  // 扣除列表
  const deductList = computed(() => {
    const detail = balanceDetailMap.value.get(balanceId.value);
    if (detail && Object.keys(detail.data)) {
      const { id, billType } = detail.data;
      const billTypeText = billType === 0 ? '月度' : '季度';
      return [{
        id,
        amount: '-',
        // 此处原名扣除佣金 应要求改为 阿里代发佣金
        name: `${billTypeText}阿里代发佣金`
      }];
    }
    return [];
  });

  const setLoading = (balanceId: string, bool: boolean) => {
    const detail = balanceDetailMap.value.get(balanceId);
    if (detail) {
      detail.loading = bool;
    }
  };

  // 获取结算单详情
  const getBalanceDetail = async (balanceId: string) => {
    if (!balanceDetailMap.value.get(balanceId)) {
      const data = {} as BalanceListItemType;
      const map = balanceDetailMap.value.set(balanceId, { loading: true, data });
      const detail = map.get(balanceId);
      if (detail) {
        try {
          const res = await API_BALANCE.getBalanceDetail(balanceId);
          detail.data = res.data;
          detail.loading = false;
        } catch {
          detail.loading = false;
        }
      }
    }
  };

  // 刷新结算单详情
  const refreshBalanceDetail = async (balanceId: string) => {
    const detail = balanceDetailMap.value.get(balanceId);
    if (detail) {
      try {
        detail.loading = true;
        const res = await API_BALANCE.getBalanceDetail(balanceId);
        detail.data = res.data;
        detail.loading = false;
      } catch {
        detail.loading = false;
      }
    }
  };

  // 根据id修改结算单详情状态
  const updateBalanceDetailStatusById = (balanceId: string, status: number) => {
    const detail = balanceDetailMap.value.get(balanceId);
    // 若找到当前结算单详情 且 
    // 当前结算单详情不在loading中 且
    // 当前结算单详情数据有值 且
    // 当前结算单详情状态与传入状态不同 则
    // 修改数据
    if (
      detail &&
      !detail.loading &&
      Object.keys(detail.data).length &&
      detail.data.status !== status
    ) {
      detail.data.status = status;
    }
  };

  // 删除结算单详情
  const deleteBalanceDetail = (balanceId: string) => {
    balanceDetailMap.value.delete(balanceId);
  };

  // 根据标签删除缓存
  const clearDetailByTag = (tag: TagListItemType) => {
    const { params } = tag;
    const balanceId = params.id as string;
    deleteBalanceDetail(balanceId);
  };

  // 清空结算单数据
  const clear = () => {
    balanceDetailMap.value.clear();
  };

  return {
    balanceId,
    balanceDetailMap,
    loading,
    balanceData,
    title,
    amount,
    balanceStatus,
    policyList,
    deductList,
    setLoading,
    getBalanceDetail,
    refreshBalanceDetail,
    updateBalanceDetailStatusById,
    clearDetailByTag,
    clear
  };
});