import { API_REQUEST } from '~/api/config';
import {
  BalanceListReqType,
  BalanceListRespType,
  BalanceDetailRespType,
  BalanceCalculateReqType,
  ConfirmBalanceReqType,
  BalanceMonthExportReqType,
  BalanceQuarterExportReqType
} from '~/models/balance';

const API_BALANCE = {
  // 获取结算列表
  getBalanceList (params: BalanceListReqType) {
    return API_REQUEST.get<BalanceListRespType>({
      url: 'rrrebatebalance/list/agent',
      params
    });
  },
  // 获取结算单详情
  getBalanceDetail (id: string) {
    return API_REQUEST.get<BalanceDetailRespType>({
      url: `rrrebatebalance/${id}`
    });
  },
  // 核算
  balanceCalculate (data: BalanceCalculateReqType) {
    return API_REQUEST.put({
      url: 'rrrebatebalance/confirmDetail',
      data
    });
  },
  // 月度结算单明细导出
  exportMonthBalanceDetail (params: BalanceMonthExportReqType) {
    return API_REQUEST.get<Blob>({
      url: 'rrrebatecalculate/monthDetailExport',
      params,
      responseType: 'blob',
      timeout: 5 * 60 * 1000
    });
  },
  // 导出月度账单明细(新版)
  newExportMonthBillDetail (params: BalanceMonthExportReqType) {
    return API_REQUEST.get<Blob>({
      url: 'viewrebatecalculate/monthDetailExport',
      params,
      responseType: 'blob',
      timeout: 5 * 60 * 1000
    });
  },
  // 导出季度结算单明细
  exportQuarterBalanceDetail (params: BalanceQuarterExportReqType) {
    return API_REQUEST.get<Blob>({
      url: 'rrrebatedetailquarterbetavs/export',
      params,
      responseType: 'blob',
      timeout: 5 * 60 * 1000
    });
  },
  // 确认结算单
  confirmBalance (data: ConfirmBalanceReqType) {
    return API_REQUEST.put({
      url: 'rrrebatebalance/confirmBalance',
      data
    });
  }
};

export default API_BALANCE;