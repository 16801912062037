import {
  V1AmountKeysType,
  V1StatusAmountKeysType,
  V1StatusKeysType,
  V2AmountKeysType,
  V2StatusAmountKeysType,
  V2StatusKeysType
} from './model';

// 结算单类型
export const BILL_TYPE_DIC: Record<number, string> = {
  0: '月度',
  1: '季度'
};

// 佣金类型列表
export const BILL_TYPE_LIST = [
  { label: '全部', value: 'all' },
  { label: '月度', value: '0' },
  { label: '季度', value: '1' }
];

// 结算单状态列表
export const STATUS_LIST = [
  { label: '全部', value: 'all' },
  { label: '待确认', value: '0' },
  { label: '待结算', value: '1' },
  { label: '已结算', value: '2' },
];

// 发票状态列表
export const INVOICE_STATUS_LIST = [
  { label: '全部', value: 'all' },
  { label: '待开票', value: '0' },
  { label: '已开票', value: '1' },
  { label: '已驳回', value: '3' }
];

// 发票类型字典
export const INVOICE_STATUS_DIC: Record<number, string> = {
  0: '待开票',
  1: '已开票',
  3: '已驳回'
};

// 季度列表
export const QUARTER_LIST = ['Q1', 'Q2', 'Q3', 'Q4'];

// 发票上传文件类型
export const UPLOAD_ACCEPT = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
export const UPLOAD_FILE_TYPE = ['pdf', 'jpg', 'jpeg', 'png'];

export const STATUS_DIC: Record<number, string> = {
  0: '待确认',
  1: '待结算',
  2: '已结算',
};

// 结算金额key值列表
export const V1_AMOUNT_KEYS: V1AmountKeysType[] = [
  'monthlyRebateAmount',
  'specialRebateAmount',
  'allinCloudRebateAmount',
  'quarterlyPaymentEstimate'
];
export const V2_AMOUNT_KEYS: V2AmountKeysType[] = [
  'smonthlyRebate',
  'sallinCloudRebate',
  'sallinCloudQuarterlyRebate',
  'sspecialRebate',
  'srenewRateQuarterlyRebate',
  'syearlyRebate'
];

// 结算状态key值列表
export const V1_STATUS_KEYS: V1StatusKeysType[] = [
  'monthStatus',
  'allinCloudStatus',
  'specialStatus',
  'quarterlyStatus'
];
export const V2_STATUS_KEYS: V2StatusKeysType[] = [
  'smonthStatus',
  'sallinCloudStatus',
  'sallinCloudQuarterlyStatus',
  'sspecialStatus',
  'srenewRateQuarterlyStatus',
  'syearlyRebateStatus'
];

// 结算状态及结算金额key值对应列表
export const V1_STATUS_AMOUNT_KEYS: V1StatusAmountKeysType[] = [
  { status: 'monthStatus', amount: 'monthlyRebateAmount' },
  { status: 'allinCloudStatus', amount: 'allinCloudRebateAmount' },
  { status: 'specialStatus', amount: 'specialRebateAmount' },
  { status: 'quarterlyStatus', amount: 'quarterlyPaymentEstimate' }
];
export const V2_STATUS_AMOUNT_KEYS: V2StatusAmountKeysType[] = [
  { status: 'smonthStatus', amount: 'smonthlyRebate' },
  { status: 'sallinCloudStatus', amount: 'sallinCloudRebate' },
  { status: 'sspecialStatus', amount: 'sspecialRebate' },
  { status: 'sallinCloudQuarterlyStatus', amount: 'sallinCloudQuarterlyRebate' },
  { status: 'srenewRateQuarterlyStatus', amount: 'srenewRateQuarterlyRebate' },
  { status: 'syearlyRebateStatus', amount: 'syearlyRebate' }
];

// 结算政策名称
export const V1_POLICY_TITLE_LIST = [
  '${type}佣金',
  '全栈上云${type}佣金',
  '特殊佣金',
  '季度预估'
];
export const V2_POLICY_TITLE_LIST = [
  '月度佣金',
  '全栈上云月度佣金',
  '特殊佣金',
  '全栈上云季度佣金',
  '续费率季度佣金',
  '年度佣金'
];